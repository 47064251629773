// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Import from free-brands-svg-icons

const Navbar = () => {
  const whatsappNumber = '+91 '; // Replace with your WhatsApp number
  const whatsappMessage = 'Hello, I would like to know more about your services.'; // Default message

  return (
    <nav className="navbar"> {/* Navbar container */}
      <div className="navbar-logo"> {/* Container for the logo */}
        <Link to="/"> {/* Link logo to home */}
          <img src="\images\logo_sso.png" alt="Logo" className="logo" /> {/* Logo image */}
          {/* <h2 className="navbar-name">ServaSreshta Organic</h2> */}
        </Link>
      </div>
      {/* <Link to="/">  */}
      <a href="/" className="navbar-name">Sarvashreshta Organics</a>
      {/* </Link> */}
      <div className="navbar-links"> {/* Container for navigation links */}
        {/* <Link to="/" className="navbar-link">Home</Link> */}
        {/* <Link to="/Blog" className="navbar-link">Blog</Link> */}
        <Link to="/about" className="navbar-link">About</Link>
        <Link to="/cart" className="navbar-link">
          <FontAwesomeIcon icon={faShoppingCart} /> Cart
        </Link>
      </div>
      {/* <a href="tel:+19742787473" className="call-button">
        <FontAwesomeIcon icon={faPhone} />
      </a>
      <a
        href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`}
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a> */}
    </nav>
  );
};

export default Navbar;
