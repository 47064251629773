// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    const sitemapLinks = [
        { path: "/", label: "Home" },
        { path: "/about", label: "About" },
        { path: "/about", label: "Contact" },
        { path: "/product/1", label: "Coconut Oil" },
        { path: "/product/2", label: "Ground Nut Oil" },
        { path: "/product/3", label: "Sunflower Oil" },
        { path: "/product/4", label: "Castor Oil" },
        { path: "/product/5", label: "Mustard Oil" },
        { path: "/product/6", label: "Sesame Oil" },
        { path: "/product/7", label: "Almond Oil" },
        { path: "/product/8", label: "Safflower Oil" },
        
      ];

  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <div style={styles.sitemapContainer}>
          {/* Sitemap Section */}
          <div style={styles.sitemapSection}>
            <h3 style={styles.header}>Sitemap</h3>
            <div style={styles.sitemapGrid}>
              {sitemapLinks.map((link) => (
                <div key={link.path} style={styles.sitemapItem}>
                  <Link to={link.path} style={styles.link} onMouseOver={(e) => e.target.style.color = '#63B3ED'} onMouseOut={(e) => e.target.style.color = '#A0AEC0'}>
                    {link.label}
                  </Link>
                </div>
              ))}
            </div>
          </div>

          {/* Follow Us Section */}
          <div style={styles.followUsSection}>
            <h3 style={styles.header}>Follow Us</h3>
            <div style={styles.iconContainer}>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={styles.icon} onMouseOver={(e) => e.target.style.color = '#63B3ED'} onMouseOut={(e) => e.target.style.color = '#A0AEC0'}>
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={styles.icon} onMouseOver={(e) => e.target.style.color = '#63B3ED'} onMouseOut={(e) => e.target.style.color = '#A0AEC0'}>
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={styles.icon} onMouseOver={(e) => e.target.style.color = '#63B3ED'} onMouseOut={(e) => e.target.style.color = '#A0AEC0'}>
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" style={styles.icon} onMouseOver={(e) => e.target.style.color = '#63B3ED'} onMouseOut={(e) => e.target.style.color = '#A0AEC0'}>
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>

          {/* Contact Us Section */}
          <div style={styles.contactUsSection}>
            <h3 style={styles.header}>Contact Us</h3>
            <p style={styles.contactText}>Email: <a href="mailto:sarvashreshtaorganics@gmail.com" style={styles.contactLink} onMouseOver={(e) => e.target.style.color = '#63B3ED'} onMouseOut={(e) => e.target.style.color = '#A0AEC0'}>sarvashreshtaorganics@gmail.com</a></p>
            <p style={styles.contactText}>Phone: <a href="tel:+918387007800" style={styles.contactLink} onMouseOver={(e) => e.target.style.color = '#63B3ED'} onMouseOut={(e) => e.target.style.color = '#A0AEC0'}>+91 8387007800</a>
            </p>
          </div>
        </div>
        <p style={styles.copyright}>&copy; {new Date().getFullYear()} Sarvashreshtaorganics. All rights reserved.
        <br></br> Built & maintaince by : <a href="https://nextleaptech.com" style={styles.contactLink} target="_blank" rel="noopener noreferrer" alignItems='center'>NextLeap Tech.</a>
       
        </p>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#1A202C',
    color: '#FFFFFF',
    padding: '1rem 0',
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 1rem',
  },
  sitemapContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '@media(min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  sitemapSection: {
    marginBottom: '1.5rem',
    width: '100%',
    '@media(min-width: 768px)': {
      width: 'auto',
      flex: '1 1 0%',
    },
  },
  header: {
    fontSize: '1.125rem',
    fontWeight: '600',
    marginBottom: '0.5rem',
    color: '#63B3ED',
  },
  sitemapGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
    gap: '1rem',
  },
  sitemapItem: {
    marginBottom: '0.5rem',
  },
  link: {
    color: '#A0AEC0',
    textDecoration: 'none',
    transition: 'color 0.3s',
  },
  followUsSection: {
    marginBottom: '1.5rem',
    width: '100%',
    '@media(min-width: 768px)': {
      width: 'auto',
      flex: '1 1 0%',
      marginLeft: '2rem',
    },
  },
  iconContainer: {
    display: 'flex',
    gap: '1rem',
  },
  icon: {
    color: '#A0AEC0',
    transition: 'color 0.3s',
  },
  contactUsSection: {
    width: '100%',
    '@media(min-width: 768px)': {
      width: 'auto',
      flex: '1 1 0%',
      marginLeft: '2rem',
    },
  },
  contactText: {
    marginBottom: '0.5rem',
  },
  contactLink: {
    color: '#A0AEC0',
    textDecoration: 'none',
    transition: 'color 0.3s',
  },
  copyright: {
    marginTop: '1rem',
    textAlign: 'center',
  },
};

export default Footer;
