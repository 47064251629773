import React, { useState } from 'react';
import { products } from '../products'; // Import the product data
import { Slidersimage } from '../slidersimage';
import ProductCard from './ProductCard'; // Product card component
import ProductImageSlider from './ProductImageSlider'; // The new image slider component
import WhyChooseUs from './WhyChooseUs'; // Import the Why Choose Us component
import ReviewSlider from './ReviewSlider'; // Import the Review Slider component
import MediaSlider from './MediaSlider'; // Import the Media Slider component

const ProductList = () => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filter products based on search term
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="product-list">
      <div className="offer-banner">
        <h2>Introducing wide varieties of nature's choice</h2>
      </div>
      <h1 className='h1cl'>Experience Nature’s Purest Touch – Embrace Organic, 🌿Embrace Life! 🌱</h1>
      <ProductImageSlider products={Slidersimage} /> {/* Add the image slider before the product grid */}
      <br />
      {/* <div className="search-bar">
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}
      <br />
      <div className="product-list-grid"> {/* Grid layout for product cards */}
        {filteredProducts.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            price={product.basePrice} // Minimum price
          />
        ))}
      </div>

      <WhyChooseUs />
       <MediaSlider /> 
      <ReviewSlider /> 
      
    </div>
  );
};

export default ProductList;
