import React from 'react';
import Slider from 'react-slick'; // Assuming you're using react-slick for the slider
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './ReviewSlider.css'; // Import the CSS file for styling

const reviews = [
  { id: 1, name: 'Janardhan', review: 'Amazing products! Highly recommend to everyone.', rating: 5 },
  { id: 2, name: 'Mukundha', review: 'Exceptional quality and great customer service.', rating: 4 },
  { id: 3, name: 'Saraswathi', review: 'I love their eco-friendly approach. The best!', rating: 5 },
  // Add more reviews as needed
];

const ReviewSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="review-slider">
      <h2>Customer Reviews</h2>
      <Slider {...settings}>
        {reviews.map((review) => (
          <div key={review.id} className="review">
            <p>"{review.review}"</p>
            <h4>- {review.name}</h4>
            <div className="rating">
              {Array(review.rating).fill().map((_, i) => (
                <span key={i} className="star">⭐</span>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ReviewSlider;
