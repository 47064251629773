import React from 'react';
import './WhyChooseUs.css'; // Import the CSS file for styling

const WhyChooseUs = () => {
  return (
    <div className="why-choose-us">
      <h2>Why Choose Us?</h2>
      <div className="features">
        <div className="feature">
          <h3>100% Organic</h3>
          <p>Our products are sourced from the best organic farms ensuring quality and purity.</p>
        </div>
        <div className="feature">
          <h3>Eco-Friendly</h3>
          <p>We believe in sustainable farming and eco-friendly practices for a better tomorrow.</p>
        </div>
        <div className="feature">
          <h3>Health Benefits</h3>
          <p>Experience the numerous health benefits of our organic products, free from chemicals and pesticides.</p>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
