// src/GTMNoScript.js
import React from 'react';

const GTMNoScript = () => (
  <noscript>
<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5Z63RJQN"
height="0" width="0" style="display:none;visibility:hidden"></iframe>

  </noscript>
);

export default GTMNoScript;
