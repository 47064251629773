import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './styles.css'; // Import your CSS file
import './MediaSlider.css';
import { NextArrow, PrevArrow } from './CustomArrows';

// const NextArrow = ({ onClick }) => {
//   return (
//     <div className="slick-next" onClick={onClick}>
//       &#9654;
//     </div>
//   );
// };

// const PrevArrow = ({ onClick }) => {
//   return (
//     <div className="slick-prev" onClick={onClick}>
//       &#9664;
//     </div>
//   );
// };

const importAll = (r) => {
  return r.keys().map(r);
};

const ProductImageSlider = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [images, setImages] = useState({ desktop: [], mobile: [] });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const desktopImages = importAll(require.context('../assets/slider_images/desktop', false, /\.(png|jpe?g|svg|webp)$/));
    const mobileImages = importAll(require.context('../assets/slider_images/mobile', false, /\.(png|jpe?g|svg|webp)$/));

    setImages({
      desktop: desktopImages,
      mobile: mobileImages
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="media-slider">
      <Slider {...settings}>
        {(isMobile ? images.mobile : images.desktop).map((image, index) => (
          <div key={index} className="media-item">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="media-image"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductImageSlider;
