
export const products = [


  {
    id: 1,
    name: 'Coconut OIL',
    description: `100% pure &  organic cold pressed bull driven coconut oil,
    using wood pressed oil in cooking does not foam and
     increases the good HDL cholesterol in our body,
     provides deep conditioning and moisturizes the hair 
    preventing breakage and hairfall.`,

    image: '/images/mobile/Groundnut_1.webp',
    images: [
      '/images/Coconut_1_a.jpg',
      '/images/Coconut_2_a.jpg','/images/Coconut_3_a.jpg','/images/Coconut_4_a.jpg','/images/Coconut_5_a.jpg',
    ],
    basePrice: 100, // Base price for the product
    sizes: [
      { size: '250ml', multiplier: 1 }, // Base price multiplier for each size
      { size: '500ml', multiplier: 2 },
      { size: '1L', multiplier: 4 },
      { size: '5L', multiplier: 20 },
    ],
    packages: [
      { type: 'Glass Bottle', extraCost: 20 }, // Extra cost for package types
      { type: 'TIN', extraCost: 10 },
    ],
  },


  {
    id: 2,
    name: 'Ground Nut Oil',
    description: `100% pure &organic cold pressed bull driven groundnut oil 
    high in monounsatured and polyunsaturated fats,
    it lowers the risk of heart disease, support heart health ,
    boost immunity,promotes skin health`,
    image: '/images/mobile/Groundnut_1.webp',
    images: [
      '/images/Groundnut_1-min.jpg',
      '/images/Groundnut_2-min.jpg',
      '/images/Groundnut_3-min.jpg',
      '/images/Groundnut_4-min.jpg',
      '/images/Groundnut_5-min.jpg',
      '/images/Groundnut_6-min.jpg',
    ],
    basePrice: 150, // Base price for the product
    sizes: [
      { size: '250ml', multiplier: 1 }, // Base price multiplier for each size
      { size: '500ml', multiplier: 1.8 },
      { size: '1L', multiplier: 3.5 },
      { size: '5L', multiplier: 15 },
    ],
    packages: [
      { type: 'Glass Bottle', extraCost: 20 }, // Extra cost for package types
      { type: 'TIN', extraCost: 10 },
    ],
  },
  {
    id: 3,
    name: 'Sunflower Oil',
    description: `100% pure & organic cold pressed bull driven sunflower oil, 
    maintains lipid levels in the body that is actually very good for heart health,
     the vitamin A & E in organic sunflower oil promotes healthy skin as a natural moisturizer.`,
    image: '/images/mobile/Sunflower_m_1.png',
    images: [
      '/images/grp_1.webp',
      '/images/sunflower_web1.webp',
    ],
    basePrice: 50, // Base price for the product
    sizes: [
      { size: '250ml', multiplier: 1 }, // Base price multiplier for each size
      { size: '500ml', multiplier: 1.8 },
      { size: '1L', multiplier: 3.5 },
      { size: '5L', multiplier: 15 },
    ],
    packages: [
      { type: 'Glass Bottle', extraCost: 20 }, // Extra cost for package types
      { type: 'TIN', extraCost: 10 },
    ],
  },

  {
    id: 4,
    name: 'Castor Oil',
    description: `100% pure & organic cold pressed bull driven castor oil, 
    an excellent natural remedy for constipation,
    in the small intestine castor oil is converted to
     ricinoleic acid this hastens the digestion process.`,
    image: '/images/mobile/castor_2.webp',
    images: [
      '/images/grp_1.webp',
      '/images/castor_1.webp',
    ],
    basePrice: 180, // Base price for the product
    sizes: [
      { size: '250ml', multiplier: 1 }, // Base price multiplier for each size
      { size: '500ml', multiplier: 1.8 },
      { size: '1L', multiplier: 3.5 },
      { size: '5L', multiplier: 15 },
    ],
    packages: [
      { type: 'Glass Bottle', extraCost: 20 }, // Extra cost for package types
      { type: 'TIN', extraCost: 10 },
    ],
  },

  {
    id: 5,
    name: 'Mustard Oil',
    description: `100% pure & organic cold pressed bull driven mustard oil,
    it is rich in monounsaturated and polyunsaturated fats ,
    it is really good for our heart health as it lowers the level 
    of bad cholesterol in our body . `,
    image: '/images/mobile/Mustard_m_1.png',
    images: [
      '/images/Mustard_1_a.jpg',
      '/images/grp_1.webp',
    ],
    basePrice: 190, // Base price for the product
    sizes: [
      { size: '250ml', multiplier: 1 }, // Base price multiplier for each size
      { size: '500ml', multiplier: 1.8 },
      { size: '1L', multiplier: 3.5 },
      { size: '5L', multiplier: 15 },
    ],
    packages: [
      { type: 'Glass Bottle', extraCost: 20 }, // Extra cost for package types
      { type: 'TIN', extraCost: 10 },
    ],
  },

  {
    id: 6,
    name: 'Sesame Oil',
    description: `100% pure & organic cold pressed bull driven sesame oil, 
    it contains lot of antioxidants,has strong inflammatory properties,
   help in control blood sugar.`,
    image: '/images/mobile/Sesame_m_1.png',
    images: [
      '/images/Sesame_2.webp',
      '/images/grp_1.webp',
    ],
    basePrice: 170, // Base price for the product
    sizes: [
      { size: '250ml', multiplier: 1 }, // Base price multiplier for each size
      { size: '500ml', multiplier: 1.8 },
      { size: '1L', multiplier: 3.5 },
      { size: '5L', multiplier: 15 },
    ],
    packages: [
      { type: 'Glass Bottle', extraCost: 20 }, // Extra cost for package types
      { type: 'TIN', extraCost: 10 },
    ],
  },

  {
    id: 7,
    name: 'Almond Oil',
    description: `100% pure & organic cold pressed bull driven almond oil,
    it is rich in omega 6 & 9 , which retain more moisture in skin cells 
   and vitamin E anti-oxidant which delays skin's aging process and 
   prevent under eye dark circle.`,
    image: '/images/almond.jpg',
    images: [
      '/images/Almond_1_a.png',
      '/images/Almond_2_a.jpg',
      '/images/Almond_3_a.jpg'
    ],
    basePrice: 180, // Base price for the product
    sizes: [
      { size: '250ml', multiplier: 1 }, // Base price multiplier for each size
      { size: '500ml', multiplier: 1.8 },
      { size: '1L', multiplier: 3.5 },
      { size: '5L', multiplier: 15 },
    ],
    packages: [
      { type: 'Glass Bottle', extraCost: 20 }, // Extra cost for package types
      { type: 'TIN', extraCost: 10 },
    ],
  },

  {
    id: 8,
    name: 'Safflower Oil',
    description: `100% pure & organic cold pressed bull driven sunflower oil, 
    improves good cholesterol, stabilises sugar levels and boost immunity,
     it provides instant energy as it is easily digestible, making it a healthy choice for kids,
     gym trainers and yoga enthusiasts.`,
    image: '/images/mobile/Coconut_m_2.png',
    images: [
      '/images/safflower_web_1.webp',
      '/images/grp_1.webp',
    ],
    basePrice: 200, // Base price for the product
    sizes: [
      { size: '250ml', multiplier: 1 }, // Base price multiplier for each size
      { size: '500ml', multiplier: 1.8 },
      { size: '1L', multiplier: 3.5 },
      { size: '5L', multiplier: 15 },
    ],
    packages: [
      { type: 'Glass Bottle', extraCost: 20 }, // Extra cost for package types
      { type: 'TIN', extraCost: 10 },
    ],
  },
  
  
];


