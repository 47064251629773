import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './MediaSlider.css';
import { NextArrow, PrevArrow } from './CustomArrows';

const mediaItems = [
  { id: 1, type: 'image', src: '/images/Almond_Glass_Bottel_3.webp', alt: 'Image 1' },
  // { id: 2, type: 'video', src: 'https://www.w3schools.com/html/mov_bbb.mp4', alt: 'Video 1' },
  { id: 3, type: 'image', src: '/images/tshirtback.webp', alt: 'Image 2' },
  // { id: 4, type: 'video', src: 'https://www.w3schools.com/html/movie.mp4', alt: 'Video 2' },
  { id: 5, type: 'image', src: '/images/addrs_board.webp', alt: 'Image 3' },
  { id: 6, type: 'image', src: '/images/castor_1.webp', alt: 'castor oil nature' },
  { id: 7, type: 'image', src: '/images/goundnutcleaning.webp', alt: 'ground nut cleaning' },
  { id: 8, type: 'image', src: '/images/grp_1.webp', alt: 'group photo 1' },
];

const MediaSlider = () => {
  const sliderRef = useRef(null);

  const handlePlay = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handlePause = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    cssEase: 'ease-in-out',
  };

  return (
    <div className="media-slider">
      <h2>What Our User's Say</h2>
      <Slider ref={sliderRef} {...settings}>
        {mediaItems.map((item) => (
          <div key={item.id} className="media-item">
            {item.type === 'image' ? (
              <img src={item.src} alt={item.alt} className="media-image" />
            ) : (
              <video
                controls
                className="media-video"
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handlePause}
              >
                <source src={item.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MediaSlider;
